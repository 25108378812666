<!--
 * @Author: Tan Chao
 * @Date: 2022-09-27 11:47:43
 * @LastEditTime: 2022-09-27 14:02:26
 * @LastEditors: Tan Chao
-->
<template>
    <div class="flex mb12">
       <div class="f14 f-info-grey required w86 lh-32">{{label}}</div>
        <div class="flex-1 lh-32">
            <slot></slot>
        </div>
    </div>
</template>

<script>
    export default {
        props: {
            label: {
            type: String,
            default: '',
            },
        }, 
    }
</script>

<style lang="scss" scoped>
</style>
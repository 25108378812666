<!--
 * @Description: What's this for
 * @Autor: WangYuan
 * @Date: 2022-01-13 15:31:38
 * @LastEditors: Tan Chao
 * @LastEditTime: 2022-10-13 16:59:23
-->
<template>
	<div class="flex">
		<div>
			<!-- 侧边菜单 -->
			<ul class="main-meau">
				<li class="main-meau-item" :class="activeIndex == index ? 'active' : ''" v-for="(item, index) in meauList" :key="index" @click="activeIndex = index">
					<!-- <i
              class="icon"
              :class="item.icon"
            ></i> -->
					<svg-icon :icon-class="item.icon" style="width:30px;height:30px" :class="activeIndex == index ? 'active' : 'filter-gary'"></svg-icon>
					<span class="mt4">{{ item.name }}</span>
				</li>
			</ul>
		</div>
		<div class="control">
			<control-widgets v-if="activeIndex == 0" />
			<models-list v-if="activeIndex == 1"></models-list>
			<control-panel @setBar="setBar" @reload="reload" />
			<control-config v-if="!isSetNav" ref="conSet" />
			<!-- 导航配置 -->
			<config-ctn title="导航配置" v-else>
				<config-wrap>
					<div class="mb15 f13">导航</div>
					<config-item label="选中文字颜色" class="flex f13 xb-color-picker mt12 mb15">
						<!-- <icon-select v-model="item.icon"></icon-select> -->
						<el-color-picker v-model="navigation.styles.activeColor"></el-color-picker>
					</config-item>
					<div class="nav">
						<div class="mt10 mb15 f12 f-grey">当前导航 {{ navigation.list.length }} 个，导航栏最多可创建6个</div>
						<div class="mt10 mb15 f12 f-red">主页导航路径不可修改</div>

						<div class="nav-item" :key="navigation.list[0].id">
							<config-item label="菜单图标" class="flex f13">
								<!-- <icon-select v-model="item.icon"></icon-select> -->
								<div class="flex">
									<div class="w60 h60">
										<Imgpond v-model="navigation.list[0].iconPath" :count="1" />
									</div>
									<div class="ml10 w60 h60">
										<Imgpond v-model="navigation.list[0].selectedIconPath" :count="1" />
									</div>
								</div>
							</config-item>
							<config-item label="菜单名称">
								<el-input v-model="navigation.list[0].text" size="small" maxlength="4" show-word-limit></el-input>
							</config-item>
							<config-item label="字体大小">
								<div style="margin-left: -10px;display: flex;align-items: center;">
									<el-slider :min="10" :max="40" v-model="navigation.list[0].fontSize"></el-slider>
									<span style="margin-left: 10px;">{{ navigation.list[0].fontSize }}</span>
								</div>
							</config-item>
						</div>
						<draggable v-model="navigation.list" :filter="filterDom" :disabled="dragDisabled">
							<div class="nav-item" :class="index == 0 ? 'filterDom' : ''" v-for="(item, index) in navigation.list" :key="item.id">
								<div v-if="navigation.list.length > 2" class="nav-delete" @click="navigation.list.splice(index, 1)">
									<i class="f12 icon icon-cha-"></i>
								</div>
								<config-item label="菜单图标" class="flex f13">
									<!-- <icon-select v-model="item.icon"></icon-select> -->
									<div class="flex">
										<div class="w60 h60">
											<Imgpond v-model="item.iconPath" :count="1" />
										</div>
										<div class="ml10 w60 h60">
											<Imgpond v-model="item.selectedIconPath" :count="1" />
										</div>
									</div>
								</config-item>
								<config-item label="菜单名称">
									<div @mouseenter="dragDisabled = true" @mouseleave="dragDisabled = false">
										<el-input v-model="item.text" size="small" maxlength="4" show-word-limit></el-input>
									</div>
								</config-item>
								<config-item label="字体大小">
									<div @mouseenter="dragDisabled = true" @mouseleave="dragDisabled = false" style="margin-left: -10px;display: flex;align-items: center;">
										<el-slider :min="10" :max="40" v-model="item.fontSize"></el-slider>
										<span style="margin-left: 10px;">{{ item.fontSize }}</span>
									</div>
								</config-item>
								<config-item label="跳转页面" v-if="index > 0">
									<jump-select v-model="item.jump"></jump-select>
									<!-- <jump-select v-model="item.jump||item.pagePath"></jump-select> -->
								</config-item>
							</div>
						</draggable>
						<div v-if="navigation.list.length < 6" class="nav-add" @click="add">
							新增导航
						</div>
					</div>
				</config-wrap>
			</config-ctn>
		</div>
	</div>
</template>

<script>
import { getProjectList } from '@/api/project';
import SideBar from '@/components/SideBar';
import ModelsList from '@/components/ModelsList';
import IconSelect from '@/components/IconSelect';
import ControlWidgets from './ControlWidgets.vue';
import ControlPanel from './ControlPanel.vue';
import ControlConfig from './ControlConfig.vue';
import { mapGetters, mapMutations } from 'vuex';
import store from '@/store';
export default {
	name: 'control',

	components: {
		ControlWidgets,
		ControlPanel,
		ControlConfig,
		IconSelect,
		ModelsList,
		SideBar,
	},

	provide() {
		return {
			control: this,
		};
	},

	data() {
		return {
			filterDom: '.el-upload-list,.filterDom',
			dragDisabled: false,
			dragstatus: false,
			curWidget: null,
			dragWidget: null,
			isSetNav: false,
			activeIndex: 0,
			meauList: [
				{
					name: '组件',
					icon: 'components',
					path: '/mall/page-build',
				},
				{
					name: '模板',
					icon: 'models',
					path: '/mall/model-manage',
				},
			],
		};
	},

	computed: {
		...mapGetters(['curPage']),
		...mapGetters(['project']),

		navigation() {
			console.log(99, this.project.config.navigation);
			return this.project.config.navigation;
		},
	},
	created() {
		if (this.$route.query.key) {
			this.setKey(this.$route.query.key);
		}
		if (this.$route.query.token) {
			this.setToken(this.$route.query.token);
			if (this.$route.query.is_admin) {
				this.$router.replace({ name: this.$route.name, query: { is_admin: this.$route.query.is_admin } });
			} else {
				this.$router.replace({ name: this.$route.name });
			}
		}
		if (store.getters.token) {
			getProjectList().then((res) => {
				if (res.data) {
					this.setProject(res.data);
				}
			});
		}
	},
	methods: {
		...mapMutations(['setToken', 'setKey', 'setProject']),
		setBar(i) {
			console.log(i);
			if (i) {
				this.isSetNav = true;
			} else {
				this.isSetNav = false;
			}
		},
		reload(v) {
			// this.curWidget = v;
			// this.curWidget = Object.assign({},this.curWidget)
			// this.$refs.conSet.reSet(v)
		},
		add() {
			this.navigation.list.push({
				text: '',
				jump: {},
			});
		},
	},
};
</script>

<style lang="scss" scoped>
.filterDom {
	display: none;
}
.control {
	position: relative;
	height: calc(100vh - 50px); /*no*/
	width: 100%;
	overflow: hidden;
	min-width: 1200px;
}
.filter-gary {
	filter: grayscale(1);
}
.xb-color-picker ::v-deep .el-color-picker__trigger {
	width: 60px;
	height: 32px;
	padding: 0;
}
.main-meau {
	width: 70px; /*no*/
	height: 100%;
	border-right: 1px solid #ebedf0; /*no*/
	// background: #22242f;
	background: #fafafa;

	.main-meau-item {
		display: flex;
		align-items: center;
		width: 100%;
		font-size: 14px; /*no*/
		padding: 16px 0;
		color: #7d7e80;
		cursor: pointer;
		flex-direction: column;
		justify-content: center;
		i {
			font-size: 30px; /*no*/
			color: #7d7e80;
		}
	}

	.active {
		color: #197aff !important;
		background: #fff;
		i {
			color: #197aff !important;
		}
		// &::after {
		//   position: absolute;
		//   top: 10px; /*no*/
		//   right: 10px; /*no*/
		//   content: "";
		//   width: 4px; /*no*/
		//   height: 21px; /*no*/
		//   border-radius: 3px; /*no*/
		//   background: $color-theme;
		// }
	}
}
</style>

<!--
 * @Description: 页面配置
 * @Autor: WangYuan
 * @Date: 2021-09-22 17:33:51
 * @LastEditors: Tan Chao
 * @LastEditTime: 2022-10-19 11:22:29
-->
<template>
	<div class="pl16 pr16 pb24">
		<div v-if="activeId">
			<template v-for="(item, index) in project.pages">
				<div :key="index" v-if="item.id == activeId">
					<el-collapse v-model="activeNames">
						<el-collapse-item title="顶部样式" name="top">
							<label-item label="页面名称">
								<template>
									<el-input v-model="item.name" @blur="messageList" size="small"></el-input>
								</template>
							</label-item>
							<label-item label="文字颜色">
								<template>
									<div class="color-pickers">
										<span class="f14">{{ RGBToHex(nameColor) }}</span>
										<div class="color-picker-border">
											<el-color-picker v-model="nameColor" show-alpha @change="changeNameColor"></el-color-picker>
										</div>
									</div>
								</template>
							</label-item>
							<label-item label="展示位置">
								<template>
									<div class="flex row-between">
										<div>{{ item.config.position ? item.config.position : '居左' }}</div>
										<el-radio-group v-model="item.config.position" size="mini" @change="messageList">
											<el-radio-button label="left">居左</el-radio-button>
											<el-radio-button label="center">居中</el-radio-button>
										</el-radio-group>
									</div>
								</template>
							</label-item>
							<label-item label="不展示名称">
								<template>
									<div class="flex row-between">
										<div></div>
										<el-switch v-model="item.config.hiddenTitle"> </el-switch>
									</div>
								</template>
							</label-item>
							<label-item label="顶部背景色">
								<template>
									<div class="color-pickers">
										<span class="f14">{{ RGBToHex(item.config.top_bgcolor) }}</span>
										<div class="color-picker-border">
											<el-color-picker v-model="item.config.top_bgcolor" show-alpha @change="isChangeColor"></el-color-picker>
										</div>
									</div>
								</template>
							</label-item>
							<label-item label="顶部背景图">
								<template>
									<div class="flex row-between">
										<span class="f12 f-grey">建议尺寸：750x100px</span>
										<Imgpond v-model="item.config.top_bgimg" :count="1" />
									</div>
								</template>
							</label-item>
						</el-collapse-item>
						<el-collapse-item title="背景样式" name="bg">
							<label-item label="页面颜色">
								<template>
									<div class="color-pickers">
										<span class="f14">{{ item.config.bg_color ? RGBToHex(item.config.bg_color) : '透明' }}</span>
										<div class="color-picker-border">
											<el-color-picker v-model="item.config.bg_color" show-alpha @change="isChangeColor"></el-color-picker>
										</div>
									</div>
								</template>
							</label-item>
							<label-item label="背景元素">
								<template>
									<div class="flex row-right flex-column">
										<div class="w200 pr2">
											<div class="bg-type flex pointer">
												<div class="bg-type-item f14" @click="changeItemBgType(1, index)" :class="item.config.bgType == 1 ? 'active-bg' : ''">颜色</div>
												<div class="bg-type-item f14" @click="changeItemBgType(2, index)" :class="item.config.bgType == 2 ? 'active-bg' : ''">图片</div>
											</div>
											<!-- 颜色选择 -->
											<div v-if="item.config.bgType == 1">
												<div class="f14 editer-color-picker">
													<div v-if="item.config.colorType == 2">
														{{ RGBToHex(item.config.backgroundColor) }} ~ {{ RGBToHex(item.config.backgroundColor1) }}
													</div>
													<div v-else>{{ RGBToHex(item.config.backgroundColor) }}</div>
													<el-popover placement="left-end" width="324" @hide="getChangeColor">
														<div style="height: 250px">
															<div class="mt12">
																<el-radio-group v-model="item.config.colorType" @change="isChangeColor">
																	<el-radio :label="1">纯色</el-radio>
																	<el-radio :label="2">渐变</el-radio>
																</el-radio-group>
															</div>
															<div class="color-picker w300">
																<span class="f10">{{ item.config.backgroundColor }}</span>
																<div class="color-picker-border">
																	<el-color-picker
																		v-model="item.config.backgroundColor"
																		:predefine="predefineColors"
																		show-alpha
																		@change="isChangeColor"
																	></el-color-picker>
																</div>
															</div>
															<div class="" v-if="item.config.colorType == 2">
																<div class="color-picker w300">
																	<span class="f10">{{ item.config.backgroundColor1 }}</span>
																	<div class="color-picker-border">
																		<el-color-picker
																			v-model="item.config.backgroundColor1"
																			:predefine="predefineColors"
																			show-alpha
																			@change="isChangeColor"
																		></el-color-picker>
																	</div>
																</div>
																<div class="mt12 flex">
																	<angle-picker theme="theme2" :angle="angle" @change="changeAngle"></angle-picker>
																</div>
															</div>
														</div>
														<div class="color-picker-border" slot="reference">
															<div class="editer-color-picker-item pointer" :style="getBgColor()"></div>
														</div>
													</el-popover>
												</div>
											</div>
											<div v-if="item.config.bgType == 2">
												<div class="mt12 flex row-right">
													<Imgpond v-model="item.config.backgroundImg" :count="1" />
												</div>
											</div>
										</div>
									</div>
								</template>
							</label-item>
							<label-item label="元素高度" v-if="item.config.bgType == 1">
								<template>
									<div class="flex row-between">
										<div class="f12 f-grey">不填则默认全屏</div>
										<div class="flex col-center">
											<div>
												<el-input size="small" class="w80" v-model="item.config.bgHeight" @blur="messageList" type="number"></el-input>
												<span class="f12 f-grey ml7">px</span>
											</div>
										</div>
									</div>
								</template>
							</label-item>
						</el-collapse-item>
						<el-collapse-item title="小程序分享" name="share">
							<label-item label="分享描述">
								<template>
									<el-input type="textarea" size="small" v-model="item.config.describe" placeholder="用户通过微信分享给朋友时显示描述"></el-input>
								</template>
							</label-item>
							<label-item label="分享图片">
								<template>
									<div class="flex row-between">
										<span></span>
										<Imgpond v-model="item.config.shareImg" :count="1" />
									</div>
								</template>
							</label-item>
						</el-collapse-item>
					</el-collapse>
				</div>
			</template>
		</div>
		<div v-else>
			<el-collapse v-model="activeNames">
				<el-collapse-item title="顶部样式" name="top">
					<label-item label="小程序名称">
						<template>
							<el-input v-model="project.name" @blur="messageList" size="small"></el-input>
						</template>
					</label-item>
					<label-item label="文字颜色">
						<template>
							<div class="color-pickers">
								<span class="f14">{{ RGBToHex(nameColor) }}</span>
								<div class="color-picker-border">
									<el-color-picker v-model="nameColor" show-alpha :predefine="predefineColors" @change="changeNameColor"></el-color-picker>
								</div>
							</div>
						</template>
					</label-item>
					<label-item label="不展示名称">
						<template>
							<div class="flex row-between">
								<div></div>
								<el-switch v-model="project.config.hiddenTitle"> </el-switch>
							</div>
						</template>
					</label-item>
					<label-item label="展示位置">
						<template>
							<div class="flex row-between">
								<div>{{ project.config.position ? project.config.position : '居左' }}</div>
								<el-radio-group v-model="project.config.position" size="mini" @change="messageList">
									<el-radio-button label="left">居左</el-radio-button>
									<el-radio-button label="center">居中</el-radio-button>
								</el-radio-group>
							</div>
						</template>
					</label-item>
					<label-item label="顶部背景色">
						<template>
							<div class="color-pickers">
								<span class="f14">{{ RGBToHex(project.config.top_bgcolor) }}</span>
								<div class="color-picker-border">
									<el-color-picker v-model="project.config.top_bgcolor" show-alpha :predefine="predefineColors" @change="isChangeColor"></el-color-picker>
								</div>
							</div>
						</template>
					</label-item>
					<label-item label="logo图片">
						<template>
							<div class="flex row-between">
								<span class="f12 f-grey">可不上传，则仅展示名称</span>
								<Imgpond v-model="project.logo" :count="1" />
							</div>
						</template>
					</label-item>
					<label-item label="顶部背景图">
						<template>
							<div class="flex row-between">
								<span class="f12 f-grey">建议尺寸：750x100px</span>
								<Imgpond v-model="project.config.top_bgimg" :count="1" />
							</div>
						</template>
					</label-item>
					<label-item label="内置页顶部">
						<template>
							<div class="color-pickers col-top" style="align-items: flex-start">
								<p class="f14">{{ project.config.tabColor }} <br /><span class="f11 f-grey">用于配置非自定义详情页顶部背景色</span></p>
								<div class="color-picker-border">
									<el-color-picker v-model="project.config.tabColor" @change="isChangeColor"></el-color-picker>
								</div>
							</div>
						</template>
					</label-item>
				</el-collapse-item>
				<el-collapse-item title="背景样式" name="bg">
					<label-item label="页面颜色">
						<template>
							<div class="color-pickers">
								<span class="f14">{{ RGBToHex(project.config.bg_color) }}</span>
								<div class="color-picker-border">
									<el-color-picker v-model="project.config.bg_color" show-alpha :predefine="predefineColors" @change="isChangeColor"></el-color-picker>
								</div>
							</div>
						</template>
					</label-item>
					<label-item label="背景元素">
						<template>
							<div class="flex row-right flex-column">
								<div class="w200 pr2">
									<div class="bg-type flex pointer">
										<div class="bg-type-item f14" @click="changeBgType(1)" :class="project.config.bgType == 1 ? 'active-bg' : ''">颜色</div>
										<div class="bg-type-item f14" @click="changeBgType(2)" :class="project.config.bgType == 2 ? 'active-bg' : ''">图片</div>
									</div>
									<!-- 颜色选择 -->
									<div v-if="project.config.bgType == 1">
										<div class="f14 editer-color-picker">
											<div v-if="project.config.colorType == 2">
												{{ RGBToHex(project.config.backgroundColor) }} ~ {{ RGBToHex(project.config.backgroundColor1) }}
											</div>
											<div v-else>{{ RGBToHex(project.config.backgroundColor) }}</div>
											<el-popover placement="left-end" width="324" @hide="getChangeColor">
												<div style="height: 250px">
													<div class="mt12">
														<el-radio-group v-model="project.config.colorType" @change="isChangeColor">
															<el-radio :label="1">纯色</el-radio>
															<el-radio :label="2">渐变</el-radio>
														</el-radio-group>
													</div>
													<div class="color-picker w300">
														<span class="f10">{{ project.config.backgroundColor }}</span>
														<div class="color-picker-border">
															<el-color-picker
																v-model="project.config.backgroundColor"
																:predefine="predefineColors"
																show-alpha
																@change="isChangeColor"
															></el-color-picker>
														</div>
													</div>
													<div class="" v-if="project.config.colorType == 2">
														<div class="color-picker w300">
															<span class="f10">{{ project.config.backgroundColor1 }}</span>
															<div class="color-picker-border">
																<el-color-picker
																	v-model="project.config.backgroundColor1"
																	:predefine="predefineColors"
																	show-alpha
																	@change="isChangeColor"
																></el-color-picker>
															</div>
														</div>
														<div class="mt12 flex">
															<angle-picker theme="theme2" :angle="angle" @change="changeAngle"></angle-picker>
														</div>
													</div>
												</div>
												<div class="color-picker-border" slot="reference">
													<div class="editer-color-picker-item pointer" :style="getBgColor()"></div>
												</div>
											</el-popover>
										</div>
									</div>
									<div v-if="project.config.bgType == 2">
										<div class="mt12 flex row-right">
											<Imgpond v-model="project.config.backgroundImg" :count="1" />
										</div>
									</div>
								</div>
							</div>
						</template>
					</label-item>
					<label-item label="元素高度" v-if="project.config.bgType == 1">
						<template>
							<div class="flex row-between">
								<div class="f12 f-grey">不填则默认全屏</div>
								<div class="flex col-center">
									<div>
										<el-input size="small" class="w80" v-model="project.config.bgHeight" @blur="messageList" type="number"></el-input>
										<span class="f12 f-grey ml7">px</span>
									</div>
								</div>
							</div>
						</template>
					</label-item>
				</el-collapse-item>
				<el-collapse-item title="小程序分享" name="share">
					<label-item label="分享描述">
						<template>
							<el-input type="textarea" size="small" v-model="project.describe" placeholder="用户通过微信分享给朋友时显示描述"></el-input>
						</template>
					</label-item>
					<label-item label="分享图片">
						<template>
							<div class="flex row-between">
								<span></span>
								<Imgpond v-model="project.shareImg" :count="1" />
							</div>
						</template>
					</label-item>
				</el-collapse-item>
				<el-collapse-item title="闪屏与广告" name="splash_screen_ad">
					<label-item label="全屏闪屏">
						<template>
							<div style="display: flex; justify-content: space-between; align-items: center">
								<span style="font-size: 14px; font-weight: 600" v-if="ad_splash_screen_obj.is_open">打开</span>
								<span style="font-size: 14px; font-weight: 600" v-else>关闭</span>
								<el-switch v-model="ad_splash_screen_obj.is_open"> </el-switch>
							</div>
							<div style="margin-top: -10px; font-size: 11px; color: gray">用于进入小程序首页前全屏展示图</div>
						</template>
					</label-item>
					<div v-if="ad_splash_screen_obj.is_open">
						<label-item label="呈现模式">
							<template>
								<div>
									<el-radio-group v-model="ad_splash_screen_obj.show_type">
										<el-radio :label="1">轮播展示</el-radio>
										<el-radio :label="2">随机展示</el-radio>
									</el-radio-group>
								</div>
							</template>
						</label-item>
						<div style="display: flex; align-items: center; justify-content: space-between; color: #606266; margin-top: -10px">
							<div class="mt6">
								<span class="mr6">间隔</span> <el-input size="small" class="w80" v-model="ad_splash_screen_obj.ad_interval" type="number"></el-input>
								<span class="f12 f-grey ml6">毫秒</span>
							</div>
							<div v-if="ad_splash_screen_obj.show_type == 1" class="mt6">
								<span class="mr6">过度</span><el-input size="small" class="w80" v-model="ad_splash_screen_obj.ad_duration" type="number"></el-input>
								<span class="f12 f-grey ml6">毫秒</span>
							</div>
						</div>
						<div style="font-size: 13px; color: gray; margin-top: 10px"><span style="color: #606266">图片设置：</span>建议上传图片大小:800*1650px，内容请勿贴边</div>
						<div class="nav">
							<draggable v-model="ad_splash_screen_obj.ad_list" :filter="filterDom" :disabled="dragDisabled">
								<div v-for="item in ad_splash_screen_obj.ad_list" :key="item.id" class="nav-item">
									<component
										v-for="(val, key, index) in schema_child"
										:key="index"
										:is="typeToComponent[val.type]"
										v-model="item[key]"
										v-bind="val"
										@changeDisabled="changeDisabled"
									>
									</component>
									<div class="nav-delete" @click="delItem(item.id)">x</div>
								</div>
							</draggable>
							<div class="nav-add-btn" @click="addItem"><i class="el-icon-plus"></i> 新增数据</div>
						</div>
					</div>
				</el-collapse-item>
				<el-collapse-item title="其他设置" name="other">
					<label-item label="主页灰度模式">
						<template>
							<div class="color-pickers col-top" style="align-items: flex-start">
								<p class="f14">
									{{ project.config.pageMourningType ? '开启' : '关闭' }} <br /><span class="f11 f-grey" style="line-height: 14px"
										>用于默哀事件使用，以表祈祷</span
									>
								</p>
								<div class="color-picker-border">
									<el-switch v-model="project.config.pageMourningType" :active-value="true" :inactive-value="false" @change="changepageType"> </el-switch>
								</div>
							</div>
						</template>
					</label-item>
				</el-collapse-item>
			</el-collapse>
			<!-- <div class="f15  mt16 mb12 f-sh f-bold">闪屏与广告</div>
			<div class="f14 f-info-grey">
				<span class="mr12">全屏闪屏</span>
				<el-switch v-model="openAdvertisement"> </el-switch>
				<span class="f12 f-grey ml12" v-if="openAdvertisement">建议上传图片大小：580px * 820px</span>
			</div>
			<div class="image-picker mt10 r4" v-if="openAdvertisement">
				<Imgpond v-model="project.config.backgroundImg" :count="1" />
				<div class="pl30">
					<div>
						<el-radio-group v-model="advertisementType">
							<el-radio :label="1">首次弹出</el-radio>
							<el-radio :label="2">每次弹出</el-radio>
						</el-radio-group>
					</div>
					<div class="f-grey f14 mt16 flex flex-center">
						<span style="width:60px">链接：</span>
						<el-input size="small" placeholder="请输入白名单链接" v-model="advertisementHref">
						</el-input>
					</div>
				</div>
			</div> -->
		</div>
	</div>
</template>
<script>
import { mapGetters } from 'vuex';
import { debounce } from '@/utils/utils.js';
import labelItem from './labelItem.vue';
import typeToComponent from '@/config/schema-template';

export default {
	name: 'PageConfig',
	computed: {
		...mapGetters(['project', 'activeId']),
	},
	components: {
		labelItem: labelItem,
	},
	data() {
		return {
			activeNames: ['top', 'bg', 'share', 'other', 'splash_screen_ad'],
			predefineColors: [
				'#ff4500',
				'#ff8c00',
				'#ffd700',
				'#90ee90',
				'#00ced1',
				'#1e90ff',
				'#c71585',
				'rgba(255, 69, 0, 0.68)',
				'rgb(255, 120, 0)',
				'hsv(51, 100, 98)',
				'hsva(120, 40, 94, 0.5)',
				'hsl(181, 100%, 37%)',
				'hsla(209, 100%, 56%, 0.73)',
				'#c7158577',
			],
			colorArr: [
				['rgba(25, 122, 255, 1)', 'rgba(250, 140, 22, 1)', 'rgba(245, 245, 245, 1)'],
				['rgba(245, 34, 45, 1)', 'rgba(8, 217, 214, 1)', 'rgba(245, 245, 245, 1)'],
				['rgba(82, 196, 26, 1)', 'rgba(31, 171, 137, 1)', 'rgba(245, 245, 245, 1)'],
				['rgba(114, 46, 209, 1)', 'rgba(182, 26, 174, 1)', 'rgba(245, 245, 245, 1)'],
				['rgba(250, 219, 20, 1)', 'rgba(236, 97, 10, 1)', 'rgba(245, 245, 245, 1)'],
			],
			mainColor: 'rgba(25, 122, 255, 1)',
			auxiliaryColor: 'rgba(250, 140, 22, 1)',
			colorType: 1,
			angle: 180,
			nameColor: 'rgba(0,0,0,1)',
			openAdvertisement: true,
			advertisementType: 1,
			advertisementHref: '',
			is_first: true,
			// config:{
			//   config:{
			//     angle: 180,
			//     backgroundColor: "rgba(250, 219, 20, 0.2)",
			//     backgroundColor1: "rgba(245, 245, 245, 0)",
			//     nameColor: "rgba(203, 106, 106, 1)",
			//     bgType: 1,
			//     categoryTpl: {advertising: false},
			//     colorType: 2,
			//     navigation:{
			//     label: "导航",
			//     list:[],//底部导航数组
			//     styles: {background: "#fff"}
			//     }
			//   },
			//   cover: "http://110.42.184.128:8090/img/1653570327672.png",
			//   id: "628afda295a17c3fe46081ef",
			//   industry: "snacks", //分类
			//   logo: "",
			//   name: "协伴",

			//   pages:[
			//     {
			//       home: true,
			//       id: "000000",
			//       name: "首页",
			//       componentList:[]
			//     }
			//   ],
			//   type: "mall",
			//   userId: "628afd8895a17c3fe46081e3",
			//   __v: 0,
			//   _id: "628afda295a17c3fe46081ef"
			// }
			typeToComponent,
			schema_child: {
				label: { label: '标题名称', type: 'string', value: '' },
				image: { label: '封面图片', type: 'upload', value: '' },
				jump: { label: '页面跳转', type: 'jump', value: {} },
			},
			ad_splash_screen_obj: {
				is_open: false,
				show_type: 1,
				ad_list: [],
				ad_interval: 2000,
				ad_duration: 300,
			},
			dragDisabled: false,
			filterDom: '.el-upload-list,.filterDom,flex-1,el-textarea,el-input--small',
		};
	},
	created() {
		this.setInfo();
		this.activeNames = ['top', 'bg', 'share', 'other', 'splash_screen_ad'];
		this.$forceUpdate();
	},

	// 监听页面设置发生变化，通知iframe同步更新
	watch: {
		ad_splash_screen_obj: {
			handler() {
				console.log(this.ad_splash_screen_obj);
				this.project.config.ad_splash_screen_obj = this.ad_splash_screen_obj;
			},
			deep: true,
		},
		'project.config': {
			handler() {
				this.messageList();
				if (this.is_first) {
					this.setInfo();
					this.is_first = false;
				}
			},
			deep: true,
		},
		'project.pages': {
			handler(newValue, oldValue) {
				let flag = false;
				for (let i = 0; i < newValue.length; i++) {
					if (newValue[i].config !== oldValue[i].config) {
						flag = true;
					}
				}
				if (flag) {
					this.messageList();
				}
			},
			deep: true,
		},
		'project.logo': {
			handler() {
				this.messageList();
			},
			deep: true,
		},
		activeId: {
			handler(val) {
				console.log(val, '-----------------------');
				this.setInfo();
			},
			deep: true,
		},
	},
	methods: {
		changeDisabled(e) {
			this.dragDisabled = e;
		},
		addItem() {
			console.log(this.ad_splash_screen_obj.ad_list);
			if (this.ad_splash_screen_obj.ad_list.length >= 5) {
				this.$notify({
					title: '无法新增',
					message: `最多只能添加${5}条数据`,
					type: 'warning',
				});
				return;
			}

			this.ad_splash_screen_obj.ad_list.push({
				id: this.$getRandomCode(6),
			});
		},

		delItem(id) {
			let i = this.ad_splash_screen_obj.ad_list.findIndex((item) => item.id == id);
			this.ad_splash_screen_obj.ad_list.splice(i, 1);
		},

		getChangeColor() {
			console.log('背景色改变', this.project);
		},
		setInfo() {
			if (this.activeId) {
				let item = this.project.pages.find((e) => e.id == this.activeId);
				if (item.config.angle) {
					this.angle = this.$cloneDeep(item.config.angle);
				}
				if (item.config.nameColor) {
					this.nameColor = this.$cloneDeep(item.config.nameColor);
				}
			} else {
				if (this.project.config.angle) {
					this.angle = this.$cloneDeep(this.project.config.angle);
				} else {
					this.project.config.angle = this.angle;
				}
				if (this.project.config.nameColor) {
					this.nameColor = this.project.config.nameColor;
				}
				if (!this.project.config.mainColor) {
					this.project.config.mainColor = 'rgba(25, 122, 255, 1)';
				}
				if (!this.project.config.tabColor) {
					this.project.config.tabColor = '#2e5bff';
				}
				if (!this.project.config.auxiliaryColor) {
					this.project.config.auxiliaryColor = 'rgba(250, 140, 22, 1)';
				}
				// if(!this.project.config.pageType){
				//   this.project.config.pageType = false
				// }
			}
			try {
				if (this.project.config.ad_splash_screen_obj) {
					this.ad_splash_screen_obj.is_open = this.project.config.ad_splash_screen_obj.is_open;
					this.ad_splash_screen_obj.show_type = this.project.config.ad_splash_screen_obj.show_type;
					this.ad_splash_screen_obj.ad_list = this.project.config.ad_splash_screen_obj.ad_list;
					if (this.project.config.ad_splash_screen_obj.ad_interval != undefined)
						this.ad_splash_screen_obj.ad_interval = this.project.config.ad_splash_screen_obj.ad_interval;
					if (this.project.config.ad_splash_screen_obj.ad_duration != undefined)
						this.ad_splash_screen_obj.ad_duration = this.project.config.ad_splash_screen_obj.ad_duration;
				}
			} catch (error) {}
		},
		changeNameColor() {
			if (this.activeId) {
				this.project.pages.forEach((item) => {
					if (item.id == this.activeId) {
						item.config.nameColor = this.nameColor;
					}
				});
			} else {
				this.project.config.nameColor = this.nameColor;
			}
			this.throttle(this.messageList, 500)();
		},
		RGBToHex(rgba) {
			if (rgba) {
				try {
					let str = rgba.slice(5, rgba.length - 1),
						arry = str.split(','),
						// opa = Number(arry[3].trim())*100,
						strHex = '#',
						r = Number(arry[0].trim()),
						g = Number(arry[1].trim()),
						b = Number(arry[2].trim());

					strHex += ((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1);
					return strHex;
				} catch (error) {
					return '';
				}
			} else {
				return '透明';
			}
		},
		changeColor(item) {
			console.log(item);
			this.project.config.mainColor = item[0];
			this.project.config.auxiliaryColor = item[1];
			let str = this.$cloneDeep(item[0]);
			this.project.config.backgroundColor = str.split('1)')[0] + '0.2)';
			this.project.config.backgroundColor1 = this.$cloneDeep(item[2]).split('1)')[0] + '0)';
			this.throttle(this.messageList, 500)();
		},
		changeBgType(v) {
			this.project.config.bgType = v;
			this.throttle(this.messageList, 500)();
		},
		changeItemBgType(v, i) {
			this.project.pages[i].config.bgType = v;
			this.throttle(this.messageList, 500)();
		},
		getBg(v) {
			let result = {
				background: v,
			};
			return result;
		},
		getBgColor() {
			if (this.activeId) {
				let item = this.project.pages.find((e) => e.id == this.activeId);
				if (item.config.backgroundColor) {
					if (item.config.colorType == 1) {
						item.config.backgroundColor1 = item.config.backgroundColor;
					}
					if (item.config.angle) {
						let result = {
							background: `linear-gradient(${item.config.angle}deg,${item.config.backgroundColor}, ${item.config.backgroundColor1})`,
						};
						return result;
					}
				} else {
					let result = {
						backgroundImage:
							'url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAwAAAAMCAIAAADZF8uwAAAAGUlEQVQYV2M4gwH+YwCGIasIUwhT25BVBADtzYNYrHvv4gAAAABJRU5ErkJggg==)',
					};
					return result;
				}
			} else {
				if (this.project.config.backgroundColor) {
					if (this.project.config.colorType == 1) {
						this.project.config.backgroundColor1 = this.project.config.backgroundColor;
					}
					let result = {
						background: `linear-gradient(${this.project.config.angle}deg,${this.project.config.backgroundColor}, ${this.project.config.backgroundColor1})`,
					};
					return result;
				} else {
					let result = {
						backgroundImage:
							'url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAwAAAAMCAIAAADZF8uwAAAAGUlEQVQYV2M4gwH+YwCGIasIUwhT25BVBADtzYNYrHvv4gAAAABJRU5ErkJggg==)',
					};
					return result;
				}
			}
		},
		changeAngle(val) {
			this.angle = val;
			this.setAngle();
		},
		setAngle: debounce(function () {
			if (this.activeId) {
				this.project.pages.forEach((item) => {
					if (item.id == this.activeId) {
						item.config.angle = this.angle;
					}
				});
			} else {
				this.project.config.angle = this.angle;
			}
			this.messageList();
			// this.throttle(this.messageList, 500)();
		}, 500),
		changepageType() {
			this.$eventBus.$emit('changePage');
		},
		isChangeColor() {
			this.$eventBus.$emit('changePage');
		},
		changedAngle() {
			this.throttle(this.messageList, 500)();
		},
		messageList() {
			this.$eventBus.$emit('changePage');
			// this.$refs.iframe.contentWindow.postMessage(
			//   {
			//     even: "init",
			//     params: { project: this.project },
			//   },
			//   "*"
			// );
		},
		// 节流
		throttle(func, wait) {
			let timeout;
			return function () {
				let context = this;
				let args = arguments;
				if (!timeout) {
					timeout = setTimeout(() => {
						timeout = null;
						func.apply(context, args);
					}, wait);
				}
			};
		},
	},
};
</script>
<style lang="scss" scoped>
.page-label {
	font-size: 12px;
}

::v-deep .el-upload-list--picture-card .el-upload-list__item {
	margin: 0;
}

.color-item {
	padding: 3px 6px;
	border: 1px solid #dcdfe6;
	margin-right: 10px;
	border-radius: 2px;
	justify-content: space-around;
	width: 90px;
}

::v-deep .el-collapse {
	border: none;
}

::v-deep .el-collapse-item__header {
	border: none;
	font-size: 15px;
	font-weight: bold;
}

// ::v-deep .el-collapse-item__wrap{
//   border: none
// }
.image-picker {
	padding: 14px 10px;
	background: #f8f8f8;
	display: flex;
	align-items: center;

	::v-deep .el-upload--picture-card {
		margin-bottom: 0;
	}
}

.color-box {
	height: 15px;
	width: 13px;
}

.color-picker-border {
	border: 1px solid #c0c4cc;
	border-radius: 4px;
	display: flex;
	align-items: center;
	padding: 2px;
}

.editer-color-picker {
	padding: 16px 12px;
	border-radius: 4px;
	display: flex;
	align-items: center;
	justify-content: space-between;

	.editer-color-picker-item {
		height: 16px;
		width: 32px;
		border-radius: 4px;
		border: 1px solid #c0c4cc;
	}
}

.bg-type {
	background: #f6f7f9;
	border-radius: 4px;

	.bg-type-item {
		width: 50%;
		text-align: center;
		height: 32px;
		line-height: 32px;
		color: #909399;
		border-radius: 4px;
	}

	.active-bg {
		color: #000;
		box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.16);
		background: #fff;
	}
}

.required {
	position: relative;
}

.required::before {
	content: '*';
	position: absolute;
	left: -8px;
	top: 0;
	color: red;
}

::v-deep .theme2 p {
	margin-top: -60px;
	color: #29aadf;
	padding-left: 6px;
}

::v-deep .angle-picker {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;

	.circle {
		// width: 80px;
		// height: 80px;
		border: 6px solid #f5f5f5;
	}
}

::v-deep .c-pointer {
	width: 54%;
}

.color-picker {
	background: #f8f8f8;
	padding: 14px;
	margin-top: 12px;
	border-radius: 4px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 156px;
	overflow: hidden;
}

.color-picker ::v-deep .el-color-picker__trigger .el-color-picker__color .el-color-picker__color-inner,
.color-picker ::v-deep .el-color-picker__trigger .el-color-picker__color,
.color-picker ::v-deep .el-color-picker__trigger,
.color-picker ::v-deep .el-color-picker {
	width: 32px;
	height: 16px;
	padding: 0;
}

.color-pickers {
	border-radius: 4px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	overflow: hidden;
}

.color-pickers ::v-deep .el-color-picker__trigger .el-color-picker__color .el-color-picker__color-inner,
.color-pickers ::v-deep .el-color-picker__trigger .el-color-picker__color,
.color-pickers ::v-deep .el-color-picker__trigger,
.color-pickers ::v-deep .el-color-picker {
	width: 32px;
	height: 16px;
	padding: 0;
}
</style>
<style lang="scss" scoped>
::v-deep .bg-white .f-grey {
	margin-top: -6px;
}

.nav {
	background: #fff;
}

::v-deep .nav-item {
	background: #f8f8f8 !important;
}

::v-deep .config-item {
	margin-bottom: 10px !important;
}

.nav-add-btn {
	color: #197aff;
	border: 1px dashed #dcdfe6;
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	background: #fff;
	padding: 14px;
	border-radius: 4px;
	font-size: 14px;
	cursor: pointer;

	.el-icon-plus {
		font-size: 12px;
		padding-right: 2px;
	}
}
</style>

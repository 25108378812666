<!--
 * @Author: Tan Chao
 * @Date: 2022-05-17 14:58:17
 * @LastEditTime: 2022-06-30 15:04:39
 * @email: 673287912@qq.com
-->

<template>
	<div>
		<div class="build">
			<control />
		</div>
	</div>
</template>

<script>
import Control from '@/components/Control';
export default {
	components: {
		Control,
	},
};
</script>
<style lang="scss" scoped>
.build {
	height: 100vh;
	background: #f7f8fa;
}
</style>
